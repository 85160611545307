import * as React from "react";
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useI18n } from 'src/utils/lni18n';
import recorderService, { RecorderStates, gainAtom, recordingAtom } from "./Recoderservice";
import { RecorderProgress } from "./RecorderProgress";
import { VuMeter } from "./VuMeter/VuMeter";
import { atom, useAtom } from "jotai";
import classes from "../lecture.module.scss"
import { VerticalProgress } from "src/components/VerticalProgress/VerticalProgress";


const hasRecordingAtom = atom((get) => {
    const a = get(recordingAtom);
    return a.state === RecorderStates.FINISHED || a.state === RecorderStates.PAUSED;
});

export const RecorderDialog: React.FC<{ onSubmit: (d: any) => void, onCancel: () => void }> = ({ onSubmit, onCancel }) => {

    const { languageService: t } = useI18n();

    const [gain, setGain] = useAtom(gainAtom);
    const [recordingState] = useAtom(recordingAtom);
    const [hasRecording] = useAtom(hasRecordingAtom);

    const [countDownTimer, setCountDownTimer] = React.useState(100);
    const [showTimer, setShowTimer] = React.useState(false);

    const handleSubmit = () => {
        onSubmit(true);
    }

    const cancelDialog = React.useCallback(() => {
        if (recordingState.state === RecorderStates.RECORDING) {
            recorderService.stopRecord();
        }
        onCancel();
    }, [recordingState]);

    React.useEffect(() => {
        recorderService.clearRecording();
    }, [])


    const startRecording = React.useCallback(() => {

        let num = 100;

        function countDown() {
            if (num > 0) {
                num -= 1;
                setCountDownTimer(num);
                window.setTimeout(countDown, 10);
            }
            else {
                recorderService.startRecord();
                setShowTimer(false);
            }
        }

        setCountDownTimer(100);
        setShowTimer(true);
        countDown();

    }, [setCountDownTimer, setShowTimer, countDownTimer]);


    return <>
        <span className="moveable" data-dialog-draggable>
            <ModalHeader>{t.getText("record")}</ModalHeader>
        </span>
        <ModalBody>

            <div className={`${classes.recorderBars} d-flex`}>
                <div className="mr-4">
                    <VuMeter />
                </div>
                <div className="d-flex flex-column align-items-center flex-fill">
                    <button disabled={recordingState.state === RecorderStates.RECORDING} onClick={startRecording} className="w-100 mb-3 btn btn-primary btn-small" >{t.getText("record")}</button>

                    <button disabled={recordingState.state !== RecorderStates.RECORDING}
                        onClick={recorderService.stopRecord} className={(recordingState.state === RecorderStates.UNINITIALIZED ? "invisible " : "") + " w-100 btn btn-inverse btn-small"} >
                        {t.getText("stop")}
                    </button>
                    {showTimer &&
                        <div className="flex-fill mt-4 w-100 d-flex flex-column align-items-center">
                            <VerticalProgress className="w-50" min={0} max={100} value={countDownTimer} color="warning" />
                        </div>
                    }
                </div>
                <RecorderProgress />
            </div>


            <div className="mt-3">
                <label className="form-label">{t.getText("recording.level")}</label>
                <input min={0.4} max={12} value={gain} type="range" step={0.2} onChange={(e) => setGain(parseFloat(e.target.value))} className="form-range" ></input>
            </div>
        </ModalBody>
        <ModalFooter>

            <Button disabled={!hasRecording} className="btn btn-primary btn-small" onClick={() => handleSubmit()}>{t.getText("save.short")}</Button>&nbsp;
            <Button className="btn btn-inverse btn-small" onClick={cancelDialog}>{t.getText("cancel")}</Button>
        </ModalFooter>
    </>
};
