import { atom, getDefaultStore } from "jotai";
import api from "../../../services/api/axiosService";
import { produce } from "immer";
import lectureService, { lecturesAtom } from "../LectureService";
import { CheckEncodingResponse } from "../domain/CheckEncodingResponse";
import { onePartatom } from "src/coursepart/CoursePartService";

const store = getDefaultStore();

const endPoints = {

    CHECK_ENCODNG: `/author/coursepart/lecture/CheckScreenEncodings`,
}


export const screensInEncodingAtom = atom<Record<string, string[]>>({});
screensInEncodingAtom.debugLabel = "screensInEncoding";

export class ScreenEncodingService {

    private timerId: number | undefined;

    public async addScreens(screenIds: string[], partId: string) {
        const screensAdded = store.get(screensInEncodingAtom);
        const oldList = screensAdded[partId] || [];
        const newList = [...new Set([...oldList, ...screenIds])];
        const newData = produce(screensAdded, data => {
            data[partId] = newList;
        });
        store.set(screensInEncodingAtom, newData);
        await this.runCheck.bind(this)();
    }

    public clearScreens(partId: string) {

        // present running conversions
        const presentValues = store.get(screensInEncodingAtom);
        if (!presentValues || Object.keys(presentValues).length == 0) return;

        const newValues = produce(presentValues, draft => {
            delete draft[partId];
        });
        store.set(screensInEncodingAtom, newValues);

        this.runCheck.bind(this)();
    }

    public removeScreen(screenId: string, partId: string) {
        const screens = store.get(screensInEncodingAtom);
        if (!screens[partId]) return;

        const newData = produce(screens, data => {
            data[partId] = data[partId].filter(s => s !== screenId);
        });
        store.set(screensInEncodingAtom, newData);
    }


    private async runCheck() {

        if (this.timerId != undefined) {
            window.clearTimeout(this.timerId);
            this.timerId = undefined;
        }
        let partsData = store.get(screensInEncodingAtom);
        const promises: Promise<{ partId: string, handledScreens: string[] }>[] = [];
        Object.keys(partsData).forEach((k) => {

            const screensIds = partsData[k];

            if (screensIds.length > 0) {
                promises.push(this.checkOnePart(screensIds, k));
            }
        });

        const results = await Promise.all(promises);

        const newData = produce(partsData, draft => {
            results.forEach(data => {
                if (data.handledScreens.length > 0) {
                    draft[data.partId] = draft[data.partId].filter(s => data.handledScreens.indexOf(s) < 0);
                    if (draft[data.partId].length == 0) delete draft[data.partId];
                }
            })
        });

        store.set(screensInEncodingAtom, newData);
        this.timerId = undefined;
        if (Object.keys(newData).length > 0) {
            this.timerId = window.setTimeout(this.runCheck.bind(this), 5 * 1000);
        }
    }

    private async checkOnePart(screensIds: string[], partId: string) {
        let idsHandled: string[] = [];
        var response = await api.post<CheckEncodingResponse[]>(endPoints.CHECK_ENCODNG, screensIds);
        if (response && response.status == 200 && response.data != null) {
            if (response.data && response.data.length > 0) {

                response.data.forEach(resp => {
                    lectureService.UpdateScreensFromEncodingResults(resp);
                    idsHandled = [...idsHandled, ...resp.Screens.map(sc => sc.ScreenId)];
                });
            }
        }
        return { partId, handledScreens: idsHandled };
    }

}

const screenEncodingService = new ScreenEncodingService();

export default screenEncodingService;