import { produce } from "immer";
import { atom, getDefaultStore } from "jotai";

const store = getDefaultStore();

export declare type ErrorMessage = { header: string, message: string, when: Date }



const errorsAtom = atom<ErrorMessage[]>([]);
errorsAtom.debugLabel = "errorsAtom";

export const AppErrors = atom( get => get(errorsAtom));
AppErrors.debugLabel = "AppErrors";

export const ErrorService ={

    setError: (err: ErrorMessage) => {
        const errors = store.get(errorsAtom);
        const newData = [...errors, err];
        store.set( errorsAtom, newData );
    },

    getError: ()=>{
        const errors = store.get(errorsAtom);
        return errors[errors.length -1];
    },

    clearError: (err: ErrorMessage) => {
        const errors = store.get(errorsAtom);
        const newData = errors.filter( e => e.when !== err.when );
        store.set( errorsAtom, newData);
    },

    clearAllErrors: () => {
        store.set( errorsAtom, []);
    }
}