
import { useParams } from 'react-router-dom';

import { useI18n } from 'src/utils/lni18n';
import coursePartService, { onePartatom } from '../CoursePartService';
import { useMemo, useState } from 'react';
import EvalQuestion from './EvalQuestion';
import { defaultDropAnimationSideEffects, DropAnimation, Active, DndContext, DragEndEvent, DragOverEvent, DragOverlay, KeyboardSensor, PointerSensor, UniqueIdentifier, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { useImmerAtom } from 'jotai-immer';
import { arrayMoveMutate } from 'src/utils/ArrayMethods';
import { EvaluationQuestionDTO } from '../domain/EvaluationEditDataDTO';
import { createPortal } from 'react-dom';
import { useDialog } from 'src/components/Modalservice/Dialogservice';
import { AddQuestionDialog, NewEvalQuestionRequest } from './AddQuestionDialog';
import AnswerOption from './AnswerOption';
import EvalQuestionReadOnly from './EvalQuestionReadOnly';
import ReadOnlyEvaluations from '../ReadOnly/Evaluations/ReadOnlyEvaluations';
import { AlertDialog, AlertOptions } from 'src/components/Modalservice/AlertDialog';
import StandardQuestions from './StandardQuestions';


export default function Evaluations() {

    const groupsId = "theQuestions";
    const dialogPortal = useDialog();
    const [active, setActive] = useState<Active | null>(null);
    const { partId } = useParams();
    const { languageService: t } = useI18n();


    const partAtom = useMemo(() => onePartatom(partId!), [partId]);
    const [partData, setPartDataFunction] = useImmerAtom(partAtom);
    const part = partData.Data;

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    const dropAnimation: DropAnimation = {
        sideEffects: defaultDropAnimationSideEffects({
            styles: {
                active: {
                    opacity: '0.5',
                },
            },
        }),
    };

    const dragOver = (e: DragOverEvent) => {
        const { active, over } = e;
        if (!over) {
            return;
        }
        if (active.data.current?.sortable.containerId === groupsId
            && active.data.current?.sortable.index !== undefined
            && over.data.current?.sortable.index !== undefined) {
            setPartDataFunction(partData => {
                if (partData.Data?.EvalQuestions?.CustomQuestions) {
                    arrayMoveMutate(partData.Data.EvalQuestions.CustomQuestions, active.data.current!.sortable.index, over.data.current!.sortable.index);
                }
            });
            return;
        }


        

        if (active.data.current?.sortable.containerId.indexOf("option") > -1
            && active.data.current?.sortable.index !== undefined
            && over.data.current?.sortable.index !== undefined) {

            const qid = +active.data.current.sortable.containerId.replace("options_", "");

            setPartDataFunction(partData => {
                if (partData.Data?.EvalQuestions?.CustomQuestions) {
                    const question = partData.Data?.EvalQuestions?.CustomQuestions.find(q => q.Id === qid);
                    if (question) {
                        arrayMoveMutate(question.AnswerOptions, active.data.current!.sortable.index, over.data.current!.sortable.index);
                    }
                }
            });
        }
    }

    const dragEnd = (ev: DragEndEvent) => {
        const container = active?.data?.current?.sortable.containerId;
        setActive(null);
        if (!container) return;
        if (container === groupsId) {
            coursePartService.saveEvalQuestionsOrder(partId!)
        }
        else {
            const qid = +active!.data!.current!.sortable.containerId.replace("options_", "");
            coursePartService.saveEvalOptionsOrder(partId!, qid);
        }
    };

    function getById(id: number): EvaluationQuestionDTO {
        return part?.EvalQuestions.CustomQuestions.find(q => q.Id === id)!;
    }

    const add = () => {
        dialogPortal({
            factory: (onSubmit, onCancel) => {

                return <AddQuestionDialog partId={partId!} languageService={t} onCancel={onCancel} onSubmit={onSubmit} />
            },
            size: "md"
        }).then((res: NewEvalQuestionRequest) => {
            if (res.type) {
                coursePartService.addEvalQuestion(res.type, partId!);
            }
            else if (res.partToCopyFrom) {
                coursePartService.CopyEvalsFromPart(partId!, res.partToCopyFrom);
            }
        });
    }

    const getOverlay = (active: Active | null) => {
        if (!active) return null;
        if (active!.data?.current?.sortable.containerId === groupsId) {
            return <EvalQuestion partId={partId!} dragged={true} q={getById(+active!.id)} num={-1} />
        }

        if (active!.data?.current?.sortable.containerId && active!.data?.current?.sortable.containerId.indexOf("option") > -1) {
            const qid = +active.data.current.sortable.containerId.replace("options_", "");
            const question = partData.Data?.EvalQuestions?.CustomQuestions.find(q => q.Id === qid);
            const option = question?.AnswerOptions.find(o => o.Id === active.id);
            return <AnswerOption locked={false} setScore={()=>{}} onDelete={(i) => { }} canDelete={true} onChange={(t, i) => { }} haveScores={question!.HaveScores} key={-1} option={option!} numOptions={question!.AnswerOptions.length} partId={partId!} dragged={true} />
        }

        return null;
    }

    const showStandardQuestion = () => {
        dialogPortal({
            factory: (onSubmit) => {
                const dprops: AlertOptions = {
                                            className: "",
                                            title: t.getText("standardquestions"),
                                            message: <StandardQuestions part={part!} />,
                                            languageService: t,
                                            onSubmit: onSubmit
                                        }
                return <AlertDialog  {...dprops} />
            },
            size: "md"
        });
    }

    if( part && part.Locked) return <ReadOnlyEvaluations />

    return <DndContext
        sensors={sensors}

        onDragStart={({ active }) => {
            setActive(active);
        }}
        onDragOver={dragOver}
        onDragEnd={dragEnd}
        onDragCancel={() => {
            setActive(null);
        }}
    >

        <div className="d-flex mb-5 ">
            <h3 className="flex-fill">{t.getText("evaluations")}</h3>
            <div className='text-right'>
            <button className='btn btn-primary mb-3' onClick={add}>{t.getText("add")}</button>
            <br />
            <button onClick={showStandardQuestion} className='btn btn-primary'>{t.getText("standardquestions")}</button>
            </div>
            
        </div>
        

        <div>
            {part && part.EvalQuestions && part.EvalQuestions.CustomQuestions &&
                <SortableContext items={part.EvalQuestions.CustomQuestions.map(l => l.Id)} id={groupsId}>
                    {part && part.EvalQuestions && part.EvalQuestions.CustomQuestions.map((q, i) => (
                        <div key={q.Id} >
                            {q.Locked &&
                                <EvalQuestionReadOnly partId={partId!} active={active?.id}  q={q} num={i} />
                            }
                            {!q.Locked &&
                                <EvalQuestion partId={partId!} active={active?.id}  q={q} num={i} />
                            }
                        </div>
                    )
                    )}
                </SortableContext>
            }
        </div>

        {createPortal(
            <DragOverlay adjustScale={false} dropAnimation={dropAnimation}>

                {getOverlay(active)}
            </DragOverlay>,
            document.body
        )}


    </DndContext>




}