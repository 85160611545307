
import { useI18n } from 'src/utils/lni18n';
import { EvalEditAnswerOptionDTO } from '../domain/EvaluationEditDataDTO';
import { useCallback, useEffect, useState } from 'react';
import HtmlTextEditor from 'src/components/TextEditor/HtmlTextEditor';
import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { DragHandle } from 'src/components/DragHandle/DragHandle';
import { CSS } from '@dnd-kit/utilities';
import LnIcon from 'src/components/LnIcon';
import { useDialog } from 'src/components/Modalservice/Dialogservice';
import { ConfirmationButtons, ConfirmationDialog, ConfirmationOptions } from 'src/components/Modalservice/ConfirmationDialog';

export default function AnswerOption(props: { setScore:(o:EvalEditAnswerOptionDTO, score:number)=>void, locked: boolean, canDelete: boolean, onDelete: (id: number) => void, onChange: (t: string, id: number) => void, partId: string, haveScores: boolean, numOptions: number, option: EvalEditAnswerOptionDTO, active?: UniqueIdentifier, dragged?: boolean }) {

    const { languageService: t } = useI18n();
    const dialogPortal = useDialog();
    const { setScore, numOptions, option, active, dragged, partId, haveScores, onChange, onDelete, canDelete, locked } = props;
    const [initialText, setInitialText] = useState("");
    const [invalidText, setInvalidText] = useState(false);

    useEffect(() => {
        setInitialText(option.AnswerText);
    }, [option.Id]);

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: option.Id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: dragged ? "75%" : ""
    };

    const editorSettings = {
        plugins: "",
        toolbar: "",
        valid_elements: "br,p"
    }

    const setValue = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = e.currentTarget;
        onChange(value, option.Id);

    }, []);

    

    const deleteMe = (): void => {
        dialogPortal({
            factory: (onSubmit, onCancel) => {
                const dprops: ConfirmationOptions = {
                    className: "",
                    title: t.getText("delete"),
                    message: <><div>{t.getText("delete_general_confirm")}</div></>,
                    languageService: t,
                    show: true,
                    onClose: onCancel,
                    onSubmit: onSubmit,
                    buttons: ConfirmationButtons.YesNo
                }
                return <ConfirmationDialog {...dprops} />
            },
            size: "md"
        }).then(res => {
            if (res) {
                onDelete(option.Id);
            }
        });
    }

 

    function checkText(name: string, html: string, state?: boolean | undefined, text?: string | undefined): void {
        setInvalidText(!text || text.trim() === "");
    }


    return (
        <>
            {!locked &&

                <div ref={setNodeRef} style={style} className='d-flex bg-white' key={option.Id}>
                    <div className='mr-3' {...attributes} {...listeners}><DragHandle /></div>
                    <HtmlTextEditor invalid={invalidText} setValue={checkText} editorSettings={editorSettings} className='flex-fill mb-3' onChange={setValue}
                        initialValue={initialText} name={option.Id}  ></HtmlTextEditor>
                    {haveScores && <div>
                        <select value={option.Score?.toString()} className='ml-3 form-control' onChange={(e) => setScore(option, +e.currentTarget.value)}>
                            {Array.from({ length: numOptions }, (value, index) => index + 1).map(i => <option key={i} value={i}>{i}</option>)}
                        </select>
                    </div>}

                    <button disabled={!canDelete} className='btn btn-link ml-5' onClick={() => deleteMe()}>
                        <LnIcon name="delete" className="icon-small" />
                    </button>


                </div>
            }


            {locked &&

                <div ref={setNodeRef} style={style} className='border-top pt-3 d-flex bg-white' key={option.Id}>
                    <div className='mr-3' {...attributes} {...listeners}><DragHandle /></div>
                    <div  className='flex-fill mb-3'  dangerouslySetInnerHTML ={{ __html: initialText }}  ></div>
                    {haveScores && <div>
                        <select disabled={true} value={option.Score?.toString()} className='ml-3 form-control'>
                            {Array.from({ length: numOptions }, (value, index) => index + 1).map(i => <option key={i} value={i}>{i}</option>)}
                        </select>
                    </div>}

                    <span className='ml-5' >
                        <LnIcon name="locked" className="icon-small" />
                    </span>


                </div>
            }

        </>

    )
}