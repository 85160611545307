import { useEffect } from "react";
import { ConfirmationButtons, ConfirmationDialog, ConfirmationOptions } from "src/components/Modalservice/ConfirmationDialog";
import { useDialog } from "src/components/Modalservice/Dialogservice";
import coursePartService from "src/coursepart/CoursePartService";
import { useI18n } from "src/utils/lni18n";


export default  function NavigateAwayFromPart() {

    const dialogPortal = useDialog();
    const { languageService: t } = useI18n();

    const checkPartDirty = async (partId: string, name:string) => {

        if (partId && coursePartService.isPartDirty(partId)) {

            const reply = await dialogPortal({
                factory: (onSubmit, onCancel) => {
                    const dprops: ConfirmationOptions = {
                        className: "",
                        title: `${t.getText("coursepart")} : ${name}` || t.getText("close.editor"),
                        message: <div className="preserve-white" >{t.getText("publish.before.close")}</div>,
                        languageService: t,
                        show: true,
                        onClose: onCancel,
                        onSubmit: onSubmit,
                        buttons: ConfirmationButtons.YesNo
                    }
                    return <ConfirmationDialog {...dprops} />
                },
                size: "md"
            })
            
            return reply as boolean

        }
        else {
           return false;
        }



    }

    useEffect(() => {
        coursePartService.setDirtyGui(checkPartDirty);
        return () => coursePartService.setDirtyGui(()=>Promise.resolve(false));
    }, []);
    return <></>
}