
import { useI18n } from 'src/utils/lni18n';
import { CoursePartEditResponseModel } from '../domain/CoursePartEditResponseModel';
import StandardEvalQuestion from './StandardEvalQuestion';

export default function StandardQuestions(props: { part: CoursePartEditResponseModel }) {

    const { part } = props;
    const { languageService: t } = useI18n();
   
    return <>
       <div className='mb-3 preserve-white'>{t.getText("eval.standardquestions.explained")}</div>

        {part.EvalQuestions.StandardQuestions.map((q, i) => {
            return <StandardEvalQuestion q={q} num={i} />
        })}
    </>
}