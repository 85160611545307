import { useState, useMemo } from "react";
import classes from '../lecture.module.scss';
import lectureService, { oneLectureatom } from '../LectureService';

import {
    Menu,
    Item,
    useContextMenu,
    ItemParams,
    PredicateParams,

} from "react-contexify";

import "react-contexify/dist/ReactContexify.css";
import { onePartatom } from '../../CoursePartService';
import { useDialog } from 'src/components/Modalservice/Dialogservice';
import screenService from '../Screen/ScreenService';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import { EditScreenDto } from '../domain/LectureState';
import scrapBookService from 'src/components/ScrapBook/ScrapBookService';
import ScrapBookDialog, { ScrapType } from 'src/components/ScrapBook/ScrapBookDialog';
import ViewLecture from '../../ReadOnly/Lecture/ViewLecture';
import { useI18n } from "src/utils/lni18n";
import ScreenDiffLink from "./ScreenDiffLink";
import { ScreenItemStatus } from "src/coursepart/domain/ScreenItemStatus";


const MENU_ID = "lect-menu-id";

const ViewLectureDiff = () => {

    const { partId, lectureId } = useParams();
    const [isVisibleMenu, setVisibilityMenu] = useState(false);

    const navigate = useNavigate();

    const { languageService: t } = useI18n();
    const dialogPortal = useDialog();


    const [lectureData] = useAtom(useMemo(() => oneLectureatom(lectureId!), [lectureId]));
    const screens = lectureData?.Screens || [];

    const [multipleSelected, oneSelected] = useMemo(() => {
        const num = screens && screens.filter(s => s.Selected).length;
        return [num > 1, num > 0]
    }, [screens]);

    const [partData] = useAtom(
        useMemo(() => {
            return onePartatom(partId!);
        }
            , [lectureData]));



    const { show, hideAll } = useContextMenu({
        id: MENU_ID
    });


    function gotNoVideo({ triggerEvent, props, data }: PredicateParams<EditScreenDto, any>) {
        return multipleSelected || !props?.VideoUrl;
    }

    const filterForNoscreenSelected = ({ triggerEvent, props, data }: PredicateParams<EditScreenDto, any>) => {
        return !oneSelected;
    }

    const filterForNoscreenSelectedAndDeleted = ({ triggerEvent, props, data }: PredicateParams<EditScreenDto, any>) => {
        return !oneSelected || props?.Status === ScreenItemStatus.Deleted;
    }


    function displayContextMenu(e: React.MouseEvent, screen: EditScreenDto) {

        if (!screen.Selected) {
            screenService.SelectScreen(screen, false, false);
        }

        show({
            event: e,
            props: screen
        });
    }

    async function handleItemClick(params: ItemParams<EditScreenDto, any>) {

        const { id, props }: ItemParams<EditScreenDto, any> = params;

        switch (id) {

            case "open":
                playLecture(props!.Order);
                break;

            case "copy":
                if (!!props) {
                    await scrapBookService.addScreen(props!, partId!);
                }
                break;

            case "getvideoorignal":
                getVideoOriginalFile(props!.ScreenId);
                break;
        }
    }


    const playLecture = (num: number) => {
        navigate(`${num}/view`);
    }



    const showScrapBook = () => {

        scrapBookService.loadScreens();
        dialogPortal({
            factory: (onSubmit, onCancel) => {

                return <ScrapBookDialog readOnly={true} contextId={lectureId!} type={ScrapType.screen} onCancel={onCancel} onSubmit={onSubmit} />
            }
        });
    }

    const getVideoOriginalFile = (screenId: string) => {
        lectureService.getVideoOriginal(lectureId!, screenId);
    };



    if (partData.Data && partData.Data.Locked) return <ViewLecture />


    return <div className={"scrollContent scroller "} aria-live="polite">
        <div className={"card-box big-card-box "}>
            <div className="card-box-title card-title d-flex">
                <div>

                    <h3>
                        {lectureData?.PrevName && <>
                            <ins className="diffmod">{lectureData.Name}</ins>
                            &nbsp;
                            <del className="diffmod">{lectureData.PrevName}</del>
                        </>
                        }
                        {!lectureData?.PrevName && <>{lectureData?.Name} &nbsp;</>}

                    </h3>

                    {lectureData?.PrevDescription && <div className="mt-3">
                        <ins className="diffmod preserve-white">{lectureData.Description}</ins>
                        <br />
                        <del className="diffmod preserve-white">{lectureData.PrevDescription}</del>
                    </div>
                    }
                    {!lectureData?.PrevDescription && <div className="preserve-white">{lectureData?.Description} &nbsp;</div>}

                </div>
                <Link className="btn btn-primary btn-sm" to={`./../`} >{t.getText("back")}</Link>
            </div>
            <div className="card-box-content card-body">
                <div className="d-flex flex-wrap pb-3">

                    <button type="button" onClick={() => playLecture(0)} className='mr-3 btn btn-inverse btn-small'>{t.getText("display")}</button>
                    <button type="button" onClick={showScrapBook} className='mr-3 btn btn-primary btn-small'>{t.getText("scrapbook")}</button>



                    <Link to={`./../../lectures/${lectureId}`} className='ml-auto btn btn-inverse btn-small' >{t.getText("close")} {t.getText("differences")}</Link>
                </div>

                <div className="d-flex">
                    <div className="d-flex flex-wrap border border-left-0 border-right-0 p-3">
                        <div className="mr-3 d-flex">
                            <div className="bg-primary mr-1">&nbsp;&nbsp;&nbsp;</div> {t.getText("new")}
                        </div>

                        <div className="mr-3 d-flex">
                            <div className="bg-warning mr-1">&nbsp;&nbsp;&nbsp;</div> {t.getText("changed")}
                        </div>

                        <div className="mr-3 d-flex">
                            <div className="bg-danger mr-1">&nbsp;&nbsp;&nbsp;</div> {t.getText("deleted")}
                        </div>

                        <div className="mr-3 d-flex">
                            <div className="badge badge-warning mr-1">N</div> {t.getText("order.published")}
                        </div>
                    </div>
                </div>


                <div className={"mt-5 flex-row flex-wrap " + classes.cardWrapper}>
                    {screens?.map(screen => <ScreenDiffLink playLecture={playLecture} onContextMenu={e => displayContextMenu(e, screen)} screen={screen} key={screen.ScreenId} />)}
                </div>




                <Menu id={MENU_ID} onVisibilityChange={(v: boolean) => setVisibilityMenu(v)}>

                    <Item id="open" hidden={filterForNoscreenSelected} onClick={handleItemClick} >
                        {t.getText("open")}
                    </Item>

                    <Item id="copy" hidden={filterForNoscreenSelectedAndDeleted} onClick={handleItemClick} >
                        {t.getText("copy")}
                    </Item>


                    <Item hidden={gotNoVideo} id="getvideoorignal" onClick={handleItemClick}>
                        {t.getText("download.orginal.video")}
                    </Item>




                </Menu>


            </div>
        </div>
    </div>

}

export default ViewLectureDiff;
