
import { useI18n } from '../utils/lni18n';
import { useAtom } from 'jotai';
import { allPartsAtom } from './CoursePartCollectionService';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import classes from "../components/SideBar/SideBar.module.scss"
import LnIcon from 'src/components/LnIcon';

export default function AllCourseparts() {

  const { languageService: t } = useI18n();
  const [partsData] = useAtom(allPartsAtom);
  const [searchText, setSearchText] = useState("");
  const [filteredSet, setFilteredSet] = useState(partsData);

  const filterParts = (e: React.ChangeEvent<HTMLInputElement>) => {
    let t = e.currentTarget.value;
    setSearchText(t);

    if (t && t.length > 2) {
      const reg = new RegExp(`${t}.*`, "i");
      setFilteredSet({
        OwnedParts: partsData.OwnedParts.filter(p => reg.test(p.Name)),
        EditParts: partsData.EditParts.filter(p => reg.test(p.Name)),
        Loading: false
      });
    } else {
      setFilteredSet(partsData);
    }
  }

  return <div>

    <div className={"scrollContent h-100"} aria-live="polite">
      <div className="card-box big-card-box">
        <div className="card-box-title card-title d-flex flex-wrap">
          <h3>{t.getText("courseparts")}</h3>
          <div className='m-3 '>
            <LnIcon name="eye-icon-off" className={`icon-small mr-3`} />
            {t.getText("coursepart.not.connected.to.course")}
            <br />
            <LnIcon name="alert" className='icon-small mr-3' />
            {t.getText("courspart.not.published")}
          </div>

          <div>
            <input value={searchText} type="text" onChange={(e) => filterParts(e)} placeholder={t.getText("find")} className="input form-control custom-input" />

          </div>
        </div>
        <div className="card-box-content card-body scroller">

          <div className="mb-5 row">
            <div className='col-sm-6 col-xs-12'>
              <h4>{t.getText("your.courseparts")}</h4>
              <div className='ml-3 mt-3'>
                {filteredSet.OwnedParts.map((part) => (

                  <div  key={part.Id}>
                    <Link className='position-relative' to={`/coursepart/${part.Id}/texts`} >
                      {part.ConnectedWithCourse === false && <LnIcon name="eye-icon-off" className={`icon-small ${classes.noCourseIcon}`} />}
                      {part.Name}
                      
                      {part.Dirty && <LnIcon name="alert" className='icon-small ml-3' />}
                    </Link>
                  </div>
                ))}
              </div>
            </div>

            <div className='col-sm-6 col-xs-12'>
              <h4>{t.getText("others")}</h4>
              <div className='ml-3 mt-3'>
                {filteredSet.EditParts.map((part) => (
                  <div key={part.Id}>
                    <Link className='position-relative' to={`/coursepart/${part.Id}/texts`} >
                      {part.ConnectedWithCourse === false && <LnIcon name="eye-icon-off" className={`icon-small ${classes.noCourseIcon}`} />}
                      {part.Name}

                      {part.Dirty && <LnIcon name="alert" className='icon-small ml-3' />}
                    </Link>
                  </div>
                ))}
              </div>
            </div>

            <div></div>
          </div>


        </div>
      </div>
    </div>
  </div>



}