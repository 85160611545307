
import { useNavigate, useParams } from "react-router-dom";
import { useI18n } from "src/utils/lni18n";
import { useDialog } from "src/components/Modalservice/Dialogservice";
import { useAtom } from "jotai";
import courseService, { currentCourseAtom, oneCourseatom } from "./CourseService";
import { useEffect, useMemo, useState } from "react";
import LnIcon from "src/components/LnIcon";
import { ConfirmationButtons, ConfirmationDialog, ConfirmationOptions } from "src/components/Modalservice/ConfirmationDialog";
import DirtyPartsTree from "./DirtyPartsTree/DirtyPartsTree";


const CoursePublish = () => {

  const [courseId] = useAtom(currentCourseAtom);
  const courseAtom = useMemo(() => oneCourseatom(courseId || ""), [courseId]);
  const [course] = useAtom(courseAtom);

  const { languageService: t } = useI18n();
  const dialogPortal = useDialog();
  const navigate = useNavigate();
  const [courseDirty, setCourseDirty] = useState(false);

  useEffect(() => {
    if (course && courseId) {
      setCourseDirty(courseService.arePartsInCourseDirty(courseId!));
    }
  }, [course,courseId]);


  const readOnly = !!course.Locked;

  function publishCourse(): void {

    dialogPortal({
      factory: (onSubmit, onCancel) => {
        const dprops: ConfirmationOptions = {
          className: "",
          title: t.getText("publish"),
          message: <div className="preserve-white" >{t.getText("publish.course.sure")}</div>,
          languageService: t,
          show: true,
          onClose: onCancel,
          onSubmit: onSubmit,
          buttons: ConfirmationButtons.YesNo
        }
        return <ConfirmationDialog {...dprops} />
      },
      size: "md"
    }).then(async (res: boolean) => {
      if (res) {
        courseService.publish(courseId!);
      }
    });


  }

  return (
    <div className="mb-5">

<div className="d-flex mb-3">

<h3 className="mb-4">{t.getText("publish")}</h3>
            <div className="ml-auto d-flex align-items-center">
                {course.IsDirty && <LnIcon name="alert" className='mr-3' />}
                <button disabled={!course.IsDirty} onClick={publishCourse} className="ml-auto  mb-3 btn btn-primary">{t.getText("publish")}</button>
            </div>

        </div>
        <div className='d-flex mb-3'>
            <div className='ml-auto pr-4'>
                {course.IsDirty && <span >{t.getText("course.not.published")}</span>}
                {!course.IsDirty && <span >{t.getText("published")}</span>}
            </div>
        </div>

      
      
      <div>
        <h4>{t.getText("classes")}</h4>
        <div className="preserve-white my-3">
          {t.getText("savecourse2server.current.courses")}
        </div>
        <div className="my-4">
          <table className="table table-condensed table-striped">
            <thead>
              <tr>
                <th>
                  {t.getText("name")}
                </th>

                <th>
                  {t.getText("mainteacher")}
                </th>
                <th>
                  {t.getText("startdate")}
                </th>
                <th>
                  {t.getText("enddate")}
                </th>
              </tr>
            </thead>
            <tbody>

              {course.Course.StudentClasses.map(cl => (
                <tr key={cl.Id}>
                  <td>
                    {cl.Name}
                  </td>
                  <td>
                    {cl.MainTeacher.FirstName} {cl.MainTeacher.LastName}
                  </td>
                  <td className="white-space-nowrap">
                    {t.getShortDateString(cl.StartDate)}
                  </td>
                  <td className="white-space-nowrap">
                    {t.getShortDateString(cl.ClassEndDate)}
                  </td>
                </tr>)
              )}

            </tbody>
          </table>
        </div>
      </div>

      {!readOnly &&
        <div>
          {courseDirty &&

            <div className="row my-5">
              <div className="col-sm d-flex align-items-start mb-3">
                <LnIcon name="alert" className="mr-3" />
                <div className="preserve-white">{t.getText("course.parts.dirty.explain")}</div>
              </div>

              <div className="col-sm ">
                <DirtyPartsTree course={course.Course} />
              </div>
            </div>



          }
          
        </div>
      }
    </div >

  )
}

export default CoursePublish;
