import { useNavigate, useParams } from 'react-router-dom';
import { useI18n } from 'src/utils/lni18n';
import { useAtom } from 'jotai';
import coursePartService, { partsListAtom } from 'src/coursepart/CoursePartService';
import { Card, CardBody, CardHeader } from 'reactstrap';
import LnIcon from '../LnIcon';
import { useDialog } from '../Modalservice/Dialogservice';
import { ConfirmationButtons, ConfirmationDialog, ConfirmationOptions } from '../Modalservice/ConfirmationDialog';
import classes from "./SideBar.module.scss";
import CoursesPanel from './CoursesPanel';
import { useEffect, useState } from 'react';
import { CoursePartWithConnectedDTO } from 'src/coursepart/domain/GetCoursepartsResponse';
import { urlService } from 'src/services/NavService';
import { Link } from 'react-router-dom';
import {   currentUserReadOnlyAtom } from 'src/services/user/userService';
import { Userstate } from 'src/types';


export default function SideBar(props: { setOpenSideBar: (state: boolean) => void }) {
    const { languageService: t } = useI18n();
    const navigate = useNavigate();
    const dialogPortal = useDialog();

    const { partId } = useParams();

    const [partList] = useAtom(partsListAtom);
    const [user] = useAtom(currentUserReadOnlyAtom);

    const [filteredList, setFilteredList] = useState<CoursePartWithConnectedDTO[]>([]);

    useEffect(() => {
        setFilteredList(partList.filter(p => !p.OpenFromCourse));
    }, [partList]);

    const navigateToPart = (id: string) => {

        if (id === partId) return;

        const url = coursePartService.getLastUrl(id);
        if (url) navigate(url);
    }

    const closePart = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {

        e.preventDefault();
        e.stopPropagation();

        const navigateTo = (nextPart?: string) => {
            if (nextPart) {
                navigateToPart(nextPart);
            }
            else {
                navigate(`/`);
            }
        }

        let result = await coursePartService.closePart(id);
        if (!result.confirm) {
            navigateTo(result.returnId);
        }
        else {
            dialogPortal({
                factory: (onSubmit, onCancel) => {
                    const dprops: ConfirmationOptions = {
                        className: "",
                        title: t.getText("close.editor"),
                        message: <div className="preserve-white" >{t.getText("publish.before.close")}</div>,
                        languageService: t,
                        show: true,
                        onClose: onCancel,
                        onSubmit: onSubmit,
                        buttons: ConfirmationButtons.YesNo
                    }
                    return <ConfirmationDialog {...dprops} />
                },
                size: "md"
            }).then(async (res: boolean) => {
                if (!res) {
                    result = await coursePartService.closePart(id, true);
                    navigateTo(result.returnId);
                }
                else {
                    navigate(urlService.urlToPart(id));
                }
            });

        }
    }

    return (
        <div className={`m-4 `}>

            <Link to="/" className={`${classes.topIcon}`} >
                <img src="/imgs/logo-author.svg" />
                {user?.UserState === Userstate.root &&
                    <button onClick={() => { throw new Error("ops"); }} type="button" className='btn btn-link'>
                        <LnIcon name="alert" className='icon-small' />
                    </button>
                }
            </Link>

            <div className='toggle'>
                <button className='btn btn-link p-3' onClick={() => props.setOpenSideBar(false)}>
                    <LnIcon name="previous-2" className='icon-small filter-green' />
                </button>
            </div>


            <CoursesPanel />


        </div>
    )
}
