import Dexie from "dexie";

export type OpenedCoursesData = {
    id?: number, itemid: string, name: string, dato: Date, userid: string
}

export type OpenedPartsData = {
    id?: number, itemid: string, name: string, dato: Date, userid: string, connectedWithCourse: boolean, dirty: boolean
}

export class LastOpenedDB extends Dexie {
    courses!: Dexie.Table<OpenedCoursesData, number>;
    courseParts!: Dexie.Table<OpenedPartsData, number>;
    constructor (name:string) {
        super(name);
        this.version(3).stores({
            courses: '++id, [itemid+userid]',
            courseParts: '++id, [itemid+userid]'
        });
    }
}

const lastDataDB = new LastOpenedDB("lastOpenedData");
export default lastDataDB;