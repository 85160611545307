import axios, { AxiosInterceptorManager, AxiosResponse } from 'axios';
import { API_URL } from '../../utils/constants';
import { ErrorMessage, ErrorService } from 'src/components/Error/ErrorService';
import { userService } from '../user/userService';
import languageService from 'src/utils/languageService';
import { atom, getDefaultStore } from 'jotai';

// eslint-disable-next-line
const isDateRegEx = /^\d{4}-(0[1-9]|1[0-2])-([12]\d|0[1-9]|3[01])([T\s](([01]\d|2[0-3])\:[0-5]\d|24\:00)(\:[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3])\:?([0-5]\d)?)?)$/;

const store = getDefaultStore();
export const networkErrorAtom = atom<{ message: string } | null>(null);
networkErrorAtom.debugLabel = "networkErrorAtom";

const convertDates = (obj: any) => {
    const type = typeof obj;
    switch (type) {
        case 'string':
            return convertOneDate(obj);

        case 'object':
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    obj[key] = convertDates(obj[key]);
                }
            }

            return obj;

        default:
            return obj;
    }

}
const convertOneDate = (s: any) => {

    if (isDateRegEx.test(s)) {
        return new Date(s);
    }
    return s;
}

const responseHandler = (response: AxiosResponse) => {

    if (response.headers['Content-Type']?.toString().toLowerCase() == "application/json;") {
        response.data = convertDates(response.data);
    }
    return response;
};

const errorHandler = (error: any) => {

    let processedError = error;

    if (!error.response) {

        // dispatch(networkReconnectDuck.setNetworkState(false));
        return Promise.reject({ handled: true });
    }

    if (error.response) {

        if (error.response.status === 401) {
            userService.logoutUser(true);
            processedError.handled = true;
            store.set(networkErrorAtom, {message: languageService.getText("web.logged.out") });
        }

        if (error.response.status > 401) {

            let errorText = null;
            let header = languageService.getText("error");
            let message: ErrorMessage | null = {
                when: new Date(),
                header: header,
                message: languageService.getText("servererror", ""),
            };


            switch (error.response.status) {
                case 403:
                    message = {
                        when: new Date(),
                        header: languageService.getText("error"),
                        message: languageService.getText("no.permission"),
                    };
                    break;

                case 404:
                    message = null;
                    processedError.handled = false;
                    break;
                case 405:
                    message = {
                        header: languageService.getText("error"),
                        message: languageService.getText("fatalerror"),
                        when: new Date()
                    };
                    processedError.handled = true;
                    break;

                case 500:
                    if (error.response.data && error.response.data.Message) {
                        errorText = error.response.data.Message;
                        if (languageService.haveKey(errorText)) {
                            errorText = languageService.getText(errorText);
                        }
                    }
                    else {
                        errorText = languageService.getText("servererror", errorText || "")
                        header = languageService.getText("fatalerror");
                    }
                    message = {
                        when: new Date(),
                        header: header,
                        message: errorText,
                    };
                    break;
                default:
                    break;
            }
            if (message != null) {
                ErrorService.setError(message);
            }
        }
    }
    return Promise.reject(processedError);

}

const api = axios.create({
    baseURL: API_URL,
    withCredentials: true,
});


api.interceptors.response.use(responseHandler, errorHandler);

export default api;