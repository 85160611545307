
import { FormatTimeDurationFromSeconds } from "../../utils/DateTimeFormat";
import { useI18n } from "../../utils/lni18n";
import { CSS } from '@dnd-kit/utilities';
import classes from './lecture.module.scss';
import LnIcon from '../../components/LnIcon';
import { useSortable } from '@dnd-kit/sortable';
import { UniqueIdentifier } from "@dnd-kit/core";
import { DragHandle } from "../../components/DragHandle/DragHandle";
import screenService from "./Screen/ScreenService";
import { useRef } from "react";
import WaitRipple from "src/components/WaitRipple";
import { EditScreenDto, ScreenType } from "./domain/LectureState";
import { useDropzone } from "react-dropzone";
import { getFileSizeString, parseFileSize } from "src/utils/FileSizeUtils";
import lectureService from "./LectureService";
import { useDialog } from "src/components/Modalservice/Dialogservice";
import { AlertDialog, AlertOptions } from "src/components/Modalservice/AlertDialog";

const fileOptions = {
    maxSize: "300mb",
    accepts: {
        "audio/wav": [".wav"],
        "audio/mpeg": [".mp3"],
        "audio/mp4": [".m4a"],
        "audio/ogg": [".ogg"],
        "audio/aac": [".aac"],

        "image/jpeg": [".jpeg"],
        "image/jpg": [".jpg"],
        "image/bmp": [".bmp"],
        "image/tiff": [".tif", ".tiff"],
        "image/gif": [".gif"],
        "image/png": [".png"],
    }
};

const maxSizeInBytes = parseFileSize(fileOptions.maxSize);
const maxSizeString = getFileSizeString(maxSizeInBytes);

const ScreenLink = (props: { playLecture?: (num: number) => void, onContextMenu: React.MouseEventHandler<HTMLElement>, screen: EditScreenDto, dragged?: boolean, active?: UniqueIdentifier }) => {

    const { languageService: t } = useI18n();
    const { screen, active, dragged, onContextMenu } = props;
    const dialogPortal = useDialog();7

    var timerId = useRef<number | undefined>(undefined);



    const onDrop = (files: File[]) => {
        if (files.length === 0) {
            return;
        }

        const fi = files[0];

        if (fi.size > maxSizeInBytes) {
            //setError(t.getText("file.too.big", fi.name, getFileSizeString(fi.size), maxSizeString));
            return;
        }

        const message = (<>
            <div>{t.getText("screens")}</div>
        </>);

        lectureService.screenFileUpdate(message, screen, fi);
    }

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop,
        accept: fileOptions.accepts,
        noClick: true,
        multiple: false,
        noDragEventsBubbling: true
    });


    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: screen.ScreenId });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: dragged ? "75%" : ""
    };

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.detail === 1) {
            timerId.current = window.setTimeout(() => {
                screenService.SelectScreen(screen, e.ctrlKey, e.shiftKey);
                timerId.current = undefined;
            }, 150);
        }
        else {
            if (props.playLecture) {
                if (timerId.current) {
                    window.clearTimeout(timerId.current);
                    timerId.current = undefined;
                }
                props.playLecture(screen.Order);
                screenService.SelectScreen(screen, false, false, true);
            }
        }
    }

    const classNames = [
        (screen.Selected ? classes.selectedScreen : ""),
        (active === screen.ScreenId ? classes.active : ""),
        classes.screenItem, 'pointer ',
        screen.ScreenType === ScreenType.ImageSound && isDragActive ? classes.dragOver : ""].join(' ');

    const showErrorInScreen = (screen: EditScreenDto)=>{
         dialogPortal({
                    factory: (onSubmit, onCancel) => {
                        
                        const dprops: AlertOptions = {
                            className: "",
                            title: t.getText("error"),
                            message: <div className="preserve-white" >{screen.ErrorText}</div>,
                            languageService: t,
                            onSubmit: onSubmit
                        }
        
                        return <AlertDialog  {...dprops} />
                    },
                    size: "md"
                });
    }

    return (
        <div onContextMenu={onContextMenu} onClick={handleClick} style={style} ref={setNodeRef} key={screen.ScreenId} role="listitem"
            className={classNames} >

            <div className={classes.screenBody} {...getRootProps()}>

                {(screen.PptConversion || screen.RunningConversion) &&
                    <>
                        <div className="cover50"></div>
                        <WaitRipple className="restrictTextWidth" text={t.getText("waiting.for.encoding")} />
                    </>
                }
                <div className={classes['card-title']}>
                    <div className={classes.nameDiv}>
                        <div className="m-2 badge badge-grey">{(screen.Order + 1)}</div>
                        <div className={classes.dragHandle} {...attributes} {...listeners}><DragHandle /></div>

                    </div>

                </div>
                <div className={`${classes['img-wrapper']} ${screen.RunningConversion ? classes.opacity3 : ""}`}>
                    { screen.ErrorText &&
                           <button onClick={()=>showErrorInScreen( screen )} className="btn btn-inverse btn-danger" >{t.getText("error")}</button>
                    }
                    
                    { !screen.ErrorText && 
                        <img className={classes['lecture-img']} src={screen.ThumbnailUrl} alt={screen.ScreenId} />
                    }
                </div>

                <div className={classes.footer}>

                    {screen.ScreenType === ScreenType.Video && <>
                        <span>
                            <LnIcon className={[classes['footer-icon'], 'natural'].join(' ')} name="video" />
                        </span>
                        {screen.Duration > 0 &&
                            <span className="ml-auto"><LnIcon className={[classes['footer-icon'], 'natural'].join(' ')} name="time" /> {FormatTimeDurationFromSeconds(screen.Duration, t)}</span>}
                    </>
                    }

                    {screen.ScreenType === ScreenType.ImageSound &&
                        <>
                            <button className={`btn btn-link ${classes.imgAudioButton}`} type="button" onClick={open}>
                                <span>

                                    <LnIcon className={[classes['footer-icon'], 'natural'].join(' ')} name="volume-black" />
                                    <LnIcon className={[classes['footer-icon'], 'natural ml-2'].join(' ')} name="img" />

                                </span>
                                {screen.Duration > 0 &&
                                    <span className="ml-auto"><LnIcon className={[classes['footer-icon'], 'natural'].join(' ')} name="time" /> {FormatTimeDurationFromSeconds(screen.Duration, t)}</span>}
                                {!!!screen.Duration && <span className="ml-auto">{t.getText("no.audio")}</span>}
                            </button>
                        </>
                    }
                    &nbsp;




                </div>


            </div>


        </div>
    );

}


export default ScreenLink;