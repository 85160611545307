import api from "../services/api/axiosService";
import { atom, getDefaultStore } from "jotai";
import {  GetCoursepartsResponse } from "./domain/GetCoursepartsResponse";
import { produce } from "immer";
import { IdAndNameDto } from "src/types";
import { waitRippleservice } from "src/services/WaitRippleService";

const store = getDefaultStore();

const initialState: GetCoursepartsResponse = {
    EditParts: [],
    OwnedParts: [],
    Loading: false
}

export const allPartsAtom = atom<GetCoursepartsResponse>(initialState);
allPartsAtom.debugLabel = "allPartsAtom";



const endPoints = {
    GET_PARTS: `/author/courseparts`,
    GET_LAST_PARTS: (limit: number)=> `/author/courseparts/${limit}/getlast`,
}

export class CoursePartCollectionService {

    public removePart( partId: string ){

        const state = store.get(allPartsAtom);
        const newState = produce( state, draft => {
            draft.EditParts = draft.EditParts.filter(p => p.Id !== partId);
            draft.OwnedParts = draft.OwnedParts.filter(p => p.Id !== partId);
        } );

        store.set(allPartsAtom, newState);
    }

    public addPart( part: IdAndNameDto ){
        const state = store.get(allPartsAtom);

        const newState = produce( state, draft => {
            const newPart = { ...part, ConnectedWithCourse: false };
            draft.OwnedParts =  [ newPart, ...draft.OwnedParts];
        } );

        store.set(allPartsAtom, newState);
    }
   
    public async getCourseParts(force?: boolean) {
        if (!force) {
            const state = store.get(allPartsAtom);
            if (state.EditParts.length > 0 || state.OwnedParts.length > 0) {
                return state;
            }
        }

        try {
            waitRippleservice.setState(true);
            store.set(allPartsAtom, {...initialState, Loading: true});
            const response = await api.get<GetCoursepartsResponse>(endPoints.GET_PARTS)
            if (response && response.data) {
                const data = {...response.data, Loading: false};
                store.set(allPartsAtom, data);
                return data
            }
            return response;
            
        } catch (error:any) {
            if( error.handled){
                store.set(allPartsAtom, initialState);
            }
            console.error(error);
            return {};
            
        }
        finally{
            waitRippleservice.setState(false);
        }
    }

    public clearCollection(){
        store.set(allPartsAtom, initialState);
    }
}
const coursePartCollectionService = new CoursePartCollectionService();
export default coursePartCollectionService;