import classes from "./ImagePicker.module.scss";
import { ImagePickerImageDto } from "./ImagePicker_types";
import { API_HOST } from "../../utils/constants";
import { CurrentUserDto } from "src/services/user/types";
import {
    Menu,
    Item,
    useContextMenu,
    ItemParams
} from "react-contexify";
import { useI18n } from "src/utils/lni18n";


const GET_IMAGE_THUMB_URL = (imageid: string) => `${API_HOST}/image/get/${imageid}/thumbnail`;
const MENU_ID = "img-menu-id";

const ImageList = (props: { user: CurrentUserDto, images?: ImagePickerImageDto[], onClick: Function, onDelete: (id: string)=>void  }) => {

    const { images, onClick, user, onDelete } = props;
    const { languageService: t } = useI18n();

    const { show } = useContextMenu({
        id: MENU_ID
    });

    const onContextmenu = (e: React.MouseEvent, img: ImagePickerImageDto) => {
        if (img.HaveReferences || img.User.Id !== user.Id) {
            return;
        }
        show({
            event: e,
            props: img
        });
    }
    function handleDelete(params: ItemParams<ImagePickerImageDto, any>) {
        const { id, props }: ItemParams<ImagePickerImageDto, any> = params;
        if( !props) return;

        onDelete(props.ImageId);
    }

    return <div className={classes.imgList} >
        {images && images.map(i => {
            return <div key={i.ImageId} onContextMenu={e => onContextmenu(e, i)} onClick={() => onClick(i.ImageId)} className={"m-2 pointer " + classes.thumbBox} id={"img" + i.ImageId}>

                <img src={GET_IMAGE_THUMB_URL(i.ImageId)} alt="" />
                {i.HaveReferences && <div className="red-mark-right small-mark"></div>}
                {i.User.Id === user.Id && <div className="green-mark-right small-mark"></div>}

            </div>
        })}

        <Menu id={MENU_ID}>
            <Item id="delete" onClick={handleDelete}>
                {t.getText("delete")}
            </Item>
        </Menu>


    </div>
}

export default ImageList;
