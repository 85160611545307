import React, { useRef, useEffect, useCallback, useMemo } from "react";

import lecturePlayerService, { ScreenJumps, currentLecturePlayingAtom, currentScreenAtom } from "./LecturePlayerService";
import { ScreenType } from "../domain/LectureState";

import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { useI18n } from "src/utils/lni18n";
import classes from './player.module.scss';
import LnIcon from "src/components/LnIcon";
import { useMatches, useNavigate, useParams } from "react-router-dom";
import thumbnailService from "./ThumbnailService";
import { ThumbnailItem } from "../domain/ThumbnailSrc";
import PlayProgress from "./PlayProgress";
import { useDialog } from "src/components/Modalservice/Dialogservice";
import Thumbs from "./Thumbs";
import recorderService from "../AudioRecorder/Recoderservice";
import { RecorderDialog } from "../AudioRecorder/RecorderDialog";
import { ConfirmationButtons, ConfirmationDialog, ConfirmationOptions } from "src/components/Modalservice/ConfirmationDialog";
import { useAtom } from "jotai";
import { currentUserReadOnlyAtom } from "src/services/user/userService";
import { AlertDialog, AlertOptions } from "src/components/Modalservice/AlertDialog";
import FileSaverDialog from "src/components/FileUpload/FileSaverDialog";
import { usePartPath } from "src/utils/usePartPath";
import { onePartatom } from "src/coursepart/CoursePartService";

const LectureControls = (props: { children?: any }) => {

    const [lectureData] = useAtom(currentLecturePlayingAtom);
    const partPath = usePartPath();

    const dialogPortal = useDialog();
    let hasMedia = false;

    const { partId, num, courseId, themeId, playtype } = useParams();

    const { controlsState: { fullscreen, visible }, currentLecture,
        isPlaying, playbackRate, stoppedAtCuePoint, currentPlayed
    } = lectureData!;

    const [partData] = useAtom(
        useMemo(() => {
            return onePartatom(partId!);
        }, [lectureData]));

    const readOnly = !!partData.Data?.Locked || playtype === "view";

    const [user] = useAtom(currentUserReadOnlyAtom)

    const [currentScreen] = useAtom(currentScreenAtom);

    let matches = useMatches();

    const { languageService: t } = useI18n();

    const navigate = useNavigate();

    const speeds = [0.5, 0.75, 1, 1.25, 1.5, 2];

    const thumbnailRef = useRef<HTMLDivElement>(null);

    const numOfScreens = currentLecture.Screens.length;

    if (currentScreen) {

        hasMedia = !!currentScreen.AudioUrl || !!currentScreen.VideoUrl;
    }

    /**
     * On every screen start reset thumbnails to off-screen
     */
    useEffect(() => {
        if (thumbnailRef.current == null) {
            return;
        }
        thumbnailRef.current.classList.add("off-screen");
    }, [currentScreen]);



    const quitLecture = useCallback(async () => {
        lecturePlayerService.setPlayerRunningState(false);
        navigate(`./../..`);
    }, []);

    lecturePlayerService.RegisterControls({
        Quit: quitLecture
    });


    if (!currentScreen) return null;

    const shiftScreen = (ev: any, jumpType: ScreenJumps) => {


        const num = lecturePlayerService.shiftScreenNumber(jumpType);
        navigateToScreen(num);

        ev.currentTarget.blur();

    }

    const handleProgressClick = (e: React.MouseEvent) => {

        const element = e.currentTarget as HTMLDivElement;
        const bounds = element.getBoundingClientRect();
        const pos = (e.clientX - bounds.x) / bounds.width;

        lecturePlayerService.SeekTo(pos, currentScreen!.ScreenType);

    }

    const handleProgressMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        if (currentScreen!.Duration > 0) {

            const element = e.currentTarget as HTMLDivElement;
            const time = currentScreen!.Duration * (e.clientX - element.offsetLeft) / element.offsetWidth;

            const thumbItem = thumbnailService.GetItem(time);
            const posLeft = e.clientX - (e.clientX - element.offsetLeft) / element.offsetWidth * 100
            setThumbNail(thumbItem, posLeft);
        }
    }


    const setThumbNail = (thumbItem: ThumbnailItem | undefined, imgpos: number) => {
        if (!thumbnailRef.current) {
            thumbnailRef.current!.classList.add("off-screen");
            return;
        }

        if (thumbItem != undefined) {
            thumbnailRef.current!.classList.remove("off-screen");


            const image = thumbnailRef.current!.firstElementChild as HTMLImageElement;

            image.src = thumbItem.url;
            image.style.left = -thumbItem.x + "px";
            image.style.top = -thumbItem.y + "px";
            thumbnailRef.current!.style.width = thumbItem.w + "px";
            thumbnailRef.current!.style.height = thumbItem.h + "px";
            thumbnailRef.current!.style.left = imgpos + "px";
        }
        else {
            thumbnailRef.current!.classList.add("off-screen");
        }
    }


    const handlePlayPauseClick = (e: any) => {
        lecturePlayerService.togglePlayingState()
        e.currentTarget.blur();

    }


    const handleProgressEnter = () => {
        thumbnailRef.current!.classList.remove("off-screen");
    }

    const handleProgressLeave = () => {
        setThumbNail(undefined, 0);
    }

    const getTouchOffset = (event: MouseEvent) => {
        var el = event.currentTarget as HTMLElement,
            x = 0;

        while (el && !isNaN(el.offsetLeft)) {
            x += el.offsetLeft - el.scrollLeft;
            el = el.offsetParent as HTMLElement;
        }

        return event.clientX - x;
    };

    const handleTouchMove = (event: any) => {
        var touches = event.touches;
        var touchX = getTouchOffset(touches[0]);
        var time = touchX * currentScreen!.Duration / event.target.offsetWidth;
        const thumbItem = thumbnailService.GetItem(time);
        setThumbNail(thumbItem, touchX);
    }

    const handleTouchEnd = () => {
        setThumbNail(undefined, 0);
    }

    const navigateToScreen = (num: number) => {
        navigate(`./../../${num}/${playtype}` );
    }

    const thumbsButton = useRef<HTMLButtonElement>(null);
    const showThumbs = () => {
        lecturePlayerService.HandleControlsMenuShowing(true);

        var p = dialogPortal({
            factory: (onSubmit, onCancel) => {
                return <Thumbs currentLecture={currentLecture} onSubmit={onSubmit} onCancel={onCancel} />
            },
            size: "lg"
        });

        p.then((num: number) => {
            navigateToScreen(num);
        }).catch(() => { });



        // no focus to prevent future key events
        thumbsButton.current!.blur();
    }

    const handleVolumeChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        lecturePlayerService.setVolume(+ev.currentTarget.value);
    }


    const setPlaybackRate = (e: any, num: number) => {
        lecturePlayerService.setPlaybackRate(num);
    }

    const toggletextEdit = () => {
        lecturePlayerService.toggeleTextEdit()
    }

    const toggleTranscriptEdit = () => {
        lecturePlayerService.toggleTranscriptEdit()
    }

    const editAudio = () => {
        lecturePlayerService.setWantedPlayingState(false);
        navigate(`${partPath}/lectures/${currentLecture.LectureId}/${num}/play/${currentScreen!.ScreenId}/edit`)
    }


    const startRecord = () => {

        async function doRecord() {

            recorderService.initializeAudio(true);
            await dialogPortal({
                factory: (onSubmit, onCancel) => {
                    return <RecorderDialog onCancel={onCancel} onSubmit={onSubmit} />
                },
                size: "recorder"
            });

            window.setTimeout(saveWave, 100);
        }

        const saveWave = () => {
            const uploadData = lecturePlayerService.addAudioToScreen(user!.Id, partData.Data!.Id, currentLecture.LectureId, currentScreen!.ScreenId, recorderService.getDataAsAudioSequences());
            recorderService.Reset();
            if (!uploadData) {
                dialogPortal({
                    factory: (onSubmit, onCancel) => {
                        const dprops: AlertOptions = {
                            className: "",
                            title: t.getText("error"),
                            message: <div className="preserve-white" >{t.getText("generalerror")}. No data to save</div>,
                            languageService: t,
                            onSubmit: onSubmit
                        }
                        return <AlertDialog {...dprops} />
                    },
                    size: "md"
                });

                return undefined;
            }

            dialogPortal({
                factory: (onSubmit, onCancel) => {
                    return <FileSaverDialog onCancel={onCancel} />
                },
                size: "md"
            }).catch(() => {
                uploadData.killer && uploadData.killer();
            });

        }

        lecturePlayerService.setWantedPlayingState(false);
        if (!!currentScreen.AudioUrl) {
            var p = dialogPortal({
                factory: (onSubmit, onCancel) => {
                    const dprops: ConfirmationOptions = {
                        className: "",
                        title: t.getText(""),
                        message: <div className="preserve-white" >{t.getText("sound.exists.overwrite")}</div>,
                        languageService: t,
                        show: true,
                        onClose: onCancel,
                        onSubmit: onSubmit,
                        buttons: ConfirmationButtons.YesNo
                    }
                    return <ConfirmationDialog {...dprops} />
                },
                size: "md"
            });

            p.then((answ: boolean) => {
                if (answ === true) {
                    window.setTimeout(doRecord, 100);
                }
            }).catch(() => { });
        }
        else {
            doRecord();
        }


    }

    const addCuePoint = useCallback(() => {
        lecturePlayerService.addCuePoint(currentPlayed * currentScreen?.Duration * 10)
    }, [currentPlayed]);

    return (
        <>

            <div id="lnlectcontrols"
                className={(visible ? classes.fadeIn : classes.fadeOut) + " " + classes.controls + " "} color="primary" >



                {props.children}
                <div className={(hasMedia ? " " : "invisible") + "  d-flex justify-content-between align-items-center"}>
                    <small>{t.gethhmmss(currentPlayed * currentScreen?.Duration / 100)}</small>
                    <div className="flex-grow-1 ml-2 mr-2 pt-2 pb-2 pointer position-relative"

                        onMouseMove={handleProgressMouseMove}
                        onMouseEnter={handleProgressEnter}
                        onMouseLeave={handleProgressLeave}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}
                        onTouchEndCapture={handleTouchEnd}
                    >
                        <PlayProgress onClick={handleProgressClick} currentScreen={currentScreen!} lectureData={lectureData!} />

                    </div>
                    <small>{t.gethhmmss(currentScreen?.Duration || 0)}</small>
                    <div className={classes.progressThumbnail} ref={thumbnailRef}>
                        <img src="" alt="" />
                    </div>
                </div>

                <div className="">

                    <div className={classes.buttonsbar + " mx-auto mt-2 pb-1 pl-3 pr-3 d-flex align-items-center justify-content-between"}>
                        <button title={t.getText("close")} className="btn btn-link " onClick={() => quitLecture()}>
                            <LnIcon name="off" />
                        </button>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className={classes.numbers + " small"}>{(currentScreen?.Order + 1)}/{numOfScreens}</div>
                            <div className={classes.divider + " d-none d-sm-block"}>&nbsp;</div>
                            <button title={t.getText("first")} disabled={currentLecture.AtFirstScreen} className="btn btn-link ml-xs-2" onClick={(e) => shiftScreen(e, ScreenJumps.first)}>
                                <LnIcon name="first" />
                            </button>
                            <button title={t.getText("previous")} disabled={currentLecture.AtFirstScreen} className="btn btn-link " onClick={(e) => shiftScreen(e, ScreenJumps.prev)}>
                                <LnIcon name="previous-2" />
                            </button>
                            <button title={t.getText("play")} disabled={!hasMedia || stoppedAtCuePoint} className={classes.roundButton + " btn btn-link d-flex align-items-center"} onClick={handlePlayPauseClick}>
                                {isPlaying ? <LnIcon name="pause" /> : <LnIcon name="play" />}
                            </button>
                            <button title={t.getText("next")} disabled={currentLecture.AtLastScreen} className="btn btn-link " onClick={(e) => shiftScreen(e, ScreenJumps.next)}>
                                <LnIcon name="next-2" />
                            </button>
                            <button title={t.getText("last")} disabled={currentLecture.AtLastScreen} className="btn btn-link " onClick={(e) => shiftScreen(e, ScreenJumps.last)}>
                                <LnIcon name="last" />
                            </button>



                            <div className={classes.divider + " d-none d-sm-block"}>&nbsp;</div>

                            {currentScreen.ScreenType !== ScreenType.Video && <>
                                <button title={t.getText("text.below.image")} className="btn btn-link " onClick={toggletextEdit}>
                                    <LnIcon name="textunderimage" className="filter-white" />
                                </button>

                                <button title={t.getText("show.transcripts")} className="btn btn-link " onClick={toggleTranscriptEdit}>
                                    <LnIcon name="book" className="filter-white" />
                                </button>
                                {!readOnly && !!currentScreen?.Duration &&
                                    <button title={t.getText("edit")} className="btn btn-link " onClick={editAudio}>
                                        <LnIcon name="edit" className="filter-white" />
                                    </button>
                                }
                            </>
                            }

                            <UncontrolledDropdown onClick={() => lecturePlayerService.HandleControlsMenuShowing(true)}>
                                <DropdownToggle tag="button" className="btn btn-link mr-xs-2">
                                    <LnIcon name="hare" className="filter-white" title={t.getText("playback.speed")} />
                                </DropdownToggle>


                                <DropdownMenu className="w-auto" >
                                    <div className={"d-flex flex-column justify-content-center " + classes.speed}>
                                        <LnIcon name="turtle" className="icon-small filter-white" />
                                        {speeds.map((s) => (

                                            <button key={s} onClick={(e) => setPlaybackRate(e, s)} className={"btn btn-link d-flex align-items-center justify-content-center " + classes.speeds + " " + (playbackRate === s ? classes.speedButton : "")} >
                                                <span>{Math.round(s * 100)}%</span>
                                            </button>

                                        ))}
                                        <LnIcon name="hare" className="icon-small filter-white" />
                                    </div>

                                </DropdownMenu>

                            </UncontrolledDropdown>



                        </div>
                        {!readOnly && currentScreen.ScreenType !== ScreenType.Video &&

                            <button title={t.getText("record")} className="btn btn-link mx-3" onClick={startRecord}>
                                <LnIcon className="filter-white" name="mic" />
                            </button>
                        }

                        {!readOnly && currentScreen.ScreenType === ScreenType.Video &&

                            <button title={t.getText("test")} className="btn btn-link mx-3" onClick={addCuePoint}>
                                <LnIcon className="filter-white" name="position" />
                            </button>
                        }
                        <button title={t.getText("screen.miniatures")} ref={thumbsButton} className="btn btn-link" onClick={showThumbs}>
                            <LnIcon name="slides-stack" />
                        </button>
                    </div>


                </div>

            </div>

            {/* <ScreensDialog container="#lnlectcontrols" /> */}

        </>
    )
}


export default LectureControls;